<template>
  <div
    class="d-flex flex-fill flex-row mb-0"
  >
    <h5>{{ title }}</h5>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
