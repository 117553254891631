<template>
  <div>
    <template
      v-if="deadline"
    >Deadline set to {{ deadline }}</template>
    <template
      v-else-if="estimate"
    >Estimate delivery date set to {{ estimate }}</template>
    <template
      v-else-if="sampleType"
    >Sample type changed to {{ sampleType }}</template>
    <template
      v-else-if="trackingInfo"
    >Tracking changed to {{ trackingInfo }}</template>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    deadline: {
      type: String,
      required: false,
      default: '',
    },
    estimate: {
      type: String,
      required: false,
      default: '',
    },
    sampleType: {
      type: String,
      required: false,
      default: '',
    },
    trackingInfo: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
