<template>
  <div>

    <sample-request-add-new
      :is-add-new-sample-request-sidebar-active.sync="isAddNewSampleRequestSidebarActive"
      :style-data="styleData"
      :sample-type-options="sampleTypeOptions"
      @refresh-data="refreshData"
    />

    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="!canAddRequests || !canEditStyle"
          class="mt-2"
          @click="isAddNewSampleRequestSidebarActive = true"
        >
          <span class="text-nowrap">Add Sample Request</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <hr>
      <b-col>
        <hr class="mt-2">
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center mb-2">
            <h4
              class=""
            >Sample Requests</h4>
          </b-col>
          <b-col class="d-flex justify-content-end mb-2">
            <b-button
              pill
              size="sm"
              class="mx-1"
              :variant="viewType == 'cards' ? 'primary': 'outline-primary'"
              @click="viewType = 'cards'"
            >
              <feather-icon
                icon="FolderIcon"
                size="16"
                class="mx-1"
              />
            </b-button>
            <b-button
              pill
              size="sm"
              class="mx-1"
              :variant="viewType == 'listing' ? 'primary': 'outline-primary'"
              @click="viewType = 'listing'"
            >
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mx-1"
              />
            </b-button>
          </b-col>
        </b-row>

        <b-table
          v-show="viewType=='listing'"
          ref="refSampleRequestListTable"
          class="position-relative"
          :items="fetchStyleSampleRequests"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text=""
        >

          <!-- Column: Sample Type -->
          <template #cell(sample_type)="data">
            <u><b-link
              :to="{ name: 'apps-sample-requests-edit', params: { id: data.item.id } }"
              class="text-secondary font-weight-bold"
            >
              {{ data.item.sample_type }}
            </b-link></u>
          </template>

          <template #cell(sample_status)="data">
            <b-dropdown
              v-if="data.item.sample_status_options.length > 0"
              :text="data.item.sample_status"
              :right="$store.state.appConfig.isRTL"
              :disabled="!canEditStyle"
            >
              <b-dropdown-item
                v-for="option in data.item.sample_status_options"
                :key="data.item.id + option"
                @click="setSampleRequestStatus(data.item.id, option)"
              >
                <span class="align-middle ml-50">{{ option }}</span>
              </b-dropdown-item>
            </b-dropdown>
            <div
              v-else
            >
              {{ data.item.sample_status }}
            </div>
          </template>
        </b-table>

        <b-container
          v-show="viewType=='cards'"
        >
          <b-row
            class="px-2 py-4"
          >
            <b-col
              v-for="sample in rawSampleRequests"
              :key="sample.id"
              class="sample-card-box"
            >
              <sample-request-card
                :sample="sample"
                :set-sample-request-status="setSampleRequestStatus"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCol, BContainer, BDropdown, BDropdownItem, BLink,
  BRow, BTable,
} from 'bootstrap-vue'
import {
  computed, ref, onUnmounted,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'
import SampleRequestAddNew from './SampleRequestAddNew.vue'
import SampleRequestCard from '../../sample-request/sample-requests-list/SampleRequestCard.vue'

export default {
  components: {
    SampleRequestAddNew,

    BButton,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BLink,
    BRow,
    BTable,
    SampleRequestCard,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
    sampleTypeOptions: {
      type: Array,
      required: true,
    },
    sampleStatusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  setup(props, { root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const toast = useToast()

    const isAddNewSampleRequestSidebarActive = ref(false)
    const refSampleRequestListTable = ref(null)
    const tableColumns = ['sample_type', 'deadline', 'estimate', 'sample_status']
    const viewType = ref('cards')
    const sampleRequestId = ref(0)
    const sampleRequestData = ref({})
    const rawSampleRequests = ref([])

    const canAddRequests = computed(() => (
      props.styleData.sizes.length !== 0 && props.styleData.status === 'Ready to Sample'))

    const refreshData = () => {
      refSampleRequestListTable.value.refresh()
    }

    const fetchStyleSampleRequests = (ctx, callback) => {
      store.dispatch('app-style/fetchStyleSampleRequests', {
        style: router.currentRoute.params.id,
      })
        .then(response => {
          rawSampleRequests.value = response.data
          callback(response.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            callback([])
          }
        })
    }

    const setSampleRequestStatus = (id, status) => {
      store.dispatch('app-style/updateSampleRequest',
        { id, sample_status: status })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status modified',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          refreshData()
        })
        .catch(({ response }) => {
          const { data } = response
          if (data.sample_status) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: data.sample_status[0],
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    return {
      isAddNewSampleRequestSidebarActive,
      viewType,

      tableColumns,
      refSampleRequestListTable,
      sampleRequestId,
      sampleRequestData,

      canAddRequests,
      fetchStyleSampleRequests,
      rawSampleRequests,
      refreshData,
      setSampleRequestStatus,
    }
  },
}
</script>
<style lang="css">
.sample-card-box {
  flex: 260px 0;
}
</style>
