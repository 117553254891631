<template>
  <div>
    <div>A new {{ sampleType }} is planned</div>
    <div
      v-if="deadline"
    >Deadline set to {{ deadline }}</div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    sampleType: {
      type: String,
      required: true,
    },
    deadline: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
