<template>
  <div>
    Sample was received
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
}
</script>
