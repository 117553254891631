<template>
  <div>
    <b-img
      v-if="image"
      rounded
      height="120"
      :src="image"
    />
    <div
      v-if="comments"
    >
      <span
        v-for="(line, index) in comments.split('\n')"
        :key="index"
      >
        {{ line }}<br>
      </span>
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    comments: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
