<template>
  <section class="styles-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="styleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching style data
      </h4>
      <div class="alert-body">
        No style found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-styles-list'}"
        >
          Styles
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="styleData">
      <b-col
        cols="12"
      >
        <b-button
          variant="outline-primary"
          size="md"
          class="mb-2"
          @click="goBack"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="16"
            class="mr-2 mr-sm-50"
          />Back
        </b-button>

        <b-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex mt-1 mb-1 justify-content-start">
              <div class="mr-5">
                <b-media
                  left-align
                  vertical-align="center"
                >
                  <template #aside>
                    <b-img
                      v-if="styleData.cover_image"
                      rounded
                      height="64"
                      :src="styleData.cover_image"
                    />
                  </template>
                  <h4 class="card-title mt-0 mb-1">
                    {{ styleData.name }}
                  </h4>
                  <h6 class="card-subtitle text-muted">
                    {{ styleData.ref }}
                    <b-link
                      v-if="styleData.copied_from"
                      title="Original copy"
                      target="_blank"
                      :to="{ name: 'apps-styles-edit', params: { id: styleData.copied_from }}"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                        class="mx-1"
                      />
                    </b-link>
                  </h6>
                  <h6
                    v-if="styleData.collection"
                    class="card-subtitle mt-0"
                  >
                    <b-link
                      :to="{ name: 'apps-styles-list', query: { collectionFilter: styleData.collection } }"
                    >{{ styleData.collection_name }}</b-link>
                  </h6>
                </b-media>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  Category
                </h5>
                <h6 class="card-subtitle ">
                  {{ categoryText(styleData.category) }}
                </h6>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  Client
                </h5>
                <h6 class="card-subtitle ">
                  {{ styleData.client_name }}
                </h6>
              </div>
              <div>
                <h5 class="mt-0 mb-1 text-muted">
                  Status
                </h5>
                <h6 class="card-subtitle ">
                  <template
                    v-if="!canEditStatus"
                  >
                    {{ styleData.status }}
                  </template>
                  <b-dropdown
                    v-else
                    :text="styleData.status"
                    :right="$store.state.appConfig.isRTL"
                  >
                    <b-dropdown-item
                      v-for="option in workflowStatusOptions"
                      :key="option.value"
                      @click="setStyleStatus(option.value)"
                    >
                      <span class="align-middle ml-50">{{ option.label }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </h6>
              </div>
            </div>
            <div>
              <div>
                <b-dropdown
                  variant="link"
                  no-caret
                  dropleft
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'apps-styles-view',
                           params: { id: styleData.id } }"
                  >
                    <feather-icon icon="BookOpenIcon" />
                    <span class="align-middle ml-50">View Full Style Info</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'apps-styles-copy',
                           params: { id: styleData.id } }"
                  >
                    <feather-icon icon="CopyIcon" />
                    <span class="align-middle ml-50">Copy Style</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canDeleteStyle"
                    @click="showDeleteModal = true"
                  >
                    <feather-icon icon="Trash2Icon" />
                    <span class="align-middle ml-50">Delete Style</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>

          <hr class="divider mb-2">

          <b-tabs
            v-model="tabIndex"
            pills
          >
            <!-- Tab: Main Info -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="InfoIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Main Info</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-basic
                :style-data="styleData"
                :can-edit-status="canEditStatus"
                :can-edit-style="canEditStyle"
                :workflow-status-options="workflowStatusOptions"
                :category-options="categoryOptions"
                :client-options="clientOptions"
                :brand-options="brandOptions"
                :collection-options="collectionOptions"
                :client-contact-user-options="clientContactUserOptions"
                :supplier-contact-user-options="supplierContactUserOptions"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Materials -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="LayersIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Materials</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-materials
                ref="refMaterialTab"
                :style-data="styleData"
                :can-edit-style="canEditStyle"
                :category-type-options="materialCategoryTypeOptions"
                :color-options="colorOptions"
                class="mt-2 pt-75"
                @scroll-to-bottom="scrollToBottom"
              />
            </b-tab>

            <!-- Tab: Embellishments -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="LoaderIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Embellishments</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-embellishments
                ref="refEmbellishmentTab"
                :style-data="styleData"
                :can-edit-style="canEditStyle"
                class="mt-2 pt-75"
                @scroll-to-bottom="scrollToBottom"
              />
            </b-tab>

            <!-- Tab: Sizes -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="SlidersIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Sizes and Measurements</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-sizes
                :style-data="styleData"
                :style-media="styleMedia"
                :can-edit-style="canEditStyle"
                :size-range-options="sizeRangeOptions"
                :pom-options="pomOptions"
                class="mt-2 pt-75"
                @fetch-style="fetchStyle"
                @fetch-style-media="fetchStyleMedia"
                @scroll-to-bottom="scrollToBottom"
              />
            </b-tab>

            <!-- Tab: Files -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="PaperclipIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Files</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-files
                :style-media="styleMedia"
                :can-edit-style="canEditStyle"
                class="mt-2 pt-75"
                @fetch-style-media="fetchStyleMedia"
              />
            </b-tab>

            <!-- Tab: Sample Requests -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="BoxIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Sample Requests</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-sample-requests
                :style-data="styleData"
                :can-edit-style="canEditStyle"
                :sample-type-options="sampleTypeOptions"
                :sample-status-options="sampleStatusOptions"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Prices -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Prices</span>
              </template>
              <hr class="divider mb-2">
              <style-edit-tab-prices
                :can-edit-style="canEditStyle"
                :price-internal-notes="styleData.price_internal_notes"
                :price-notes="styleData.price_notes"
                :style-data="styleData"
                class="mt-2 pt-75"
                @fetch-style="fetchStyle"
                @scroll-to-bottom="scrollToBottom"
              />
            </b-tab>
          </b-tabs>
        </b-card>
        <b-card
          v-if="feed.length > 0"
        >
          <feed
            ref="refFeed"
            :feed="feed"
            :feed-config="feedConfig"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-if="styleData"
      v-model="showDeleteModal"
      ok-title="Delete"
      title="Delete Style"
      @ok="deleteStyle"
    >
      Are you sure that you want to delete
      <b>{{ styleData.name }}</b> <span class="text-muted">{{ styleData.ref }}</span>?
    </b-modal>
  </section>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BDropdown, BDropdownItem, BImg, BLink, BMedia,
  BModal, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'
import StyleEditTabBasic from './StyleEditTabBasic.vue'
import StyleEditTabEmbellishments from './StyleEditTabEmbellishments.vue'
import StyleEditTabFiles from './StyleEditTabFiles.vue'
import StyleEditTabMaterials from './StyleEditTabMaterials.vue'
import StyleEditTabPrices from './StyleEditTabPrices.vue'
import StyleEditTabSampleRequests from './StyleEditTabSampleRequests.vue'
import StyleEditTabSizes from './StyleEditTabSizes.vue'
import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BImg,
    BLink,
    BMedia,
    BModal,
    BRow,
    BTab,
    BTabs,

    StyleEditTabBasic,
    StyleEditTabEmbellishments,
    StyleEditTabFiles,
    StyleEditTabMaterials,
    StyleEditTabPrices,
    StyleEditTabSampleRequests,
    StyleEditTabSizes,
    Feed,
  },
  setup(props, { root, refs }) {
    const toast = useToast()

    const styleData = ref(null)
    const styleMedia = ref([])
    const canEditStatus = ref(false)
    const canEditStyle = ref(false)

    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const canDeleteStyle = ref(false)
    const showDeleteModal = ref(false)

    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const tabIndex = ref(
      router.currentRoute.hash ? parseInt(router.currentRoute.hash.slice(1), 10) : 0,
    )

    watch([tabIndex], () => {
      router.replace({
        name: router.currentRoute.name,
        query: router.currentRoute.query,
        hash: `#${tabIndex.value}`,
      })
    })

    const workflowStatusOptions = computed(() => {
      if (styleData.value.status === 'Archived') {
        return [
          {
            label: styleData.value.previous_status,
            value: styleData.value.previous_status,
          },
          { label: 'Archived', value: 'Archived' },
        ]
      }
      if (userRole === 'client' || userRole === 'client-admin') {
        const options = [
          { label: 'Draft', value: 'Draft' },
          { label: 'Published', value: 'Published' },
        ]
        if (styleData && styleData.value.status !== 'Draft') {
          options.push({ label: 'Archived', value: 'Archived' })
        }
        return options
      }
      return [
        { label: 'Published', value: 'Published' },
        { label: 'Ready to Sample', value: 'Ready to Sample' },
        { label: 'Archived', value: 'Archived' },
      ]
    })
    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })
    const categoryText = computed(() => category => {
      const found = Array.from(categoryOptions.value)
        .find(option => option.value === category)
      return found ? found.label : category
    })
    const clientOptions = computed(() => {
      const options = root.$store.getters['app-style/clientOptions']
      return options
    })
    const brandOptions = computed(() => {
      const options = root.$store.getters['app-style/brandOptions']
      return options
    })
    const collectionOptions = computed(() => {
      const options = root.$store.getters['app-style/collectionOptions']
      return options
    })
    const clientContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/clientContactUserOptions']
      return options
    })
    const supplierContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/supplierContactUserOptions']
      return options
    })
    const sizeRangeOptions = computed(() => {
      const options = root.$store.getters['app-style/sizeRangeOptions']
      return options
    })
    const pomOptions = computed(() => {
      const options = root.$store.getters['app-style/pomOptions']
      return options
    })
    const materialCategoryTypeOptions = computed(() => {
      const options = root.$store.getters['app-style/materialCategoryTypeOptions']
      return options
    })
    const colorOptions = computed(() => {
      const options = root.$store.getters['app-style/colorOptions']
      return options
    })
    const sampleTypeOptions = computed(() => {
      const options = root.$store.getters['app-style/sampleTypeOptions']
      return options
    })
    const sampleStatusOptions = computed(() => {
      const options = root.$store.getters['app-style/sampleStatusOptions']
      return options
    })
    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-style/fetchStyle', { id: router.currentRoute.params.id })
        .then(response => {
          styleData.value = response.data
          if (styleData.value.status === 'Ready to Sample' && (
            userRole === 'client' || userRole === 'client-admin')) {
            canEditStatus.value = false
          } else {
            canEditStatus.value = true
          }
          if (styleData.value.status === 'Draft' && (
            userRole === 'client' || userRole === 'client-admin')) {
            canDeleteStyle.value = true
          } else {
            canDeleteStyle.value = false
          }
          canEditStyle.value = styleData.value.status !== 'Archived'
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleData.value = undefined
          }
        })
    }

    const fetchStyleMedia = () => {
      store.dispatch('app-style/fetchStyleMedia', { id: router.currentRoute.params.id })
        .then(response => { styleMedia.value = response.data.style_media })
        .catch(error => {
          if (error.response.status === 404) {
            styleMedia.value = []
          }
        })
    }

    const deleteStyle = () => {
      store.dispatch('app-style/deleteStyle', {
        id: router.currentRoute.params.id,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Style deleted',
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          }).then(router.push({ name: 'apps-styles-list' }))
        })
    }

    const setStyleStatus = status => {
      store.dispatch('app-style/editStyle', {
        id: router.currentRoute.params.id,
        status,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status modified',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).then(fetchStyle)
    }

    const goBack = () => {
      router.go(-1)
    }

    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }

    const feed = computed(() => {
      if (tabIndex.value === 1) {
        if (refs.refMaterialTab) {
          return refs.refMaterialTab.feed()
        }
      }
      if (tabIndex.value === 2) {
        if (refs.refEmbellishmentTab) {
          return refs.refEmbellishmentTab.feed()
        }
      }
      if (tabIndex.value === 3) {
        return styleData.value.feed
          .filter(item => item.feed_vars.custom_type === 'Sizes and Measurements')
      }
      if (tabIndex.value === 6) {
        return styleData.value.feed
          .filter(item => item.feed_vars.custom_type === 'Prices')
      }
      return []
    })

    const scrollToBottom = () => {
      const yOffset = -100
      if (refs.refFeed) {
        const el = refs.refFeed.$el.firstElementChild
        const elTop = el.lastElementChild.getBoundingClientRect().top
        window.scrollTo({
          top: elTop + window.pageYOffset + yOffset,
          behavior: 'smooth',
        })
      }
    }

    onMounted(optionsInit)
    onMounted(fetchStyle)
    onMounted(fetchStyleMedia)

    return {
      styleData,
      styleMedia,
      userRole,
      tabIndex,
      feed,
      feedConfig,
      canEditStyle,
      canEditStatus,
      canDeleteStyle,
      showDeleteModal,
      fetchStyle,
      fetchStyleMedia,
      setStyleStatus,
      deleteStyle,
      goBack,
      scrollToBottom,

      workflowStatusOptions,
      categoryOptions,
      categoryText,
      clientOptions,
      brandOptions,
      collectionOptions,
      clientContactUserOptions,
      supplierContactUserOptions,
      sizeRangeOptions,
      pomOptions,
      materialCategoryTypeOptions,
      colorOptions,
      sampleTypeOptions,
      sampleStatusOptions,
    }
  },
}
</script>

<style>

</style>
