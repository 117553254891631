<template>
  <div class="d-flex flex-column mt-1">
    <transition-group name="fade">
      <template
        v-for="{ id, type, feed_vars, image, created_at, created_by } in feed"
      >
        <feed-item
          v-if="!applyFilter || applyFilter.includes(type)"
          :key="id"
          :type="type"
          :feed-vars="feed_vars"
          :image="image"
          :created-at="created_at"
          :created-by="created_by"
          :feed-config="feedConfig"
        />
      </template>
    </transition-group>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

import FeedItem from './FeedItem.vue'

export default {
  components: {
    FeedItem,
  },
  props: {
    feed: {
      type: Array,
      required: true,
    },
    feedConfig: {
      type: Object,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const filters = {
      1: ['Measurement'],
      2: ['Design Construction'],
      3: ['Embellishment'],
    }
    const applyFilter = computed(() => filters[props.tabIndex])

    return {
      applyFilter,
    }
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-50px);
}
</style>
