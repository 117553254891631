<template>
  <div>
    Sample is confirmed and the estimate delivery date is set to  {{ estimate }}
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    estimate: {
      type: String,
      required: true,
    },
  },
}
</script>
