<template>
  <div>
    Sample was sent. Tracking information is {{ trackingInfo }}
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    trackingInfo: {
      type: String,
      required: true,
    },
  },
}
</script>
