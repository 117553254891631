<template>

  <div>
    <div>

      <b-card
        no-body
        class="mb-0"
      >
        <div class="mb-2">
          <div class="d-flex align-items-center justify-content-start">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  class="bg-light"
                >Select Category
                </b-input-group-text>
              </template>
              <b-form-select
                v-model="categoryType"
                :options="categoryTypeOptions"
                :disabled="!canEditStyle"
                rounded="0"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  rounded="right"
                  :to="{ name: 'apps-styles-add-embellishment',
                         params: { id: currentId, category: categoryTypeValue } }"
                  :disabled="!canEditStyle"
                >
                  <span class="text-nowrap">Add Embellishment</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          ref="refEmbellishmentListTable"
          class="position-relative"
          :items="fetchStyleEmbellishments"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="Without Embellishments"
        >

          <!-- Column: Created at -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              {{ data.item.created_at }}
            </div>
          </template>

          <!-- Column: Category type -->
          <template #cell(category_type)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                {{ data.item.category_type }}
              </div>
              <div>
                <b-avatar
                  v-for="media in imageMedia(data.item.style_embellishment_media)"
                  :key="media.id"
                  :src="media.image"
                  :style="{width: '3em', height: '3em'}"
                  :title="media.name"
                  square
                  class="mr-1"
                />
              </div>
            </div>
          </template>

          <!-- Column: Ref -->
          <template #cell(description)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="align-text-top">
                  {{ data.item.specifics_description }}
                </div>
                <div class="align-text-top">
                  {{ data.item.dimensions_description }}
                </div>
              </div>
              <feather-icon
                v-if="data.item.placement"
                v-b-tooltip.hover
                icon="TargetIcon"
                class="d-inline mr-1"
                :title="data.item.placement"
              />
              <feather-icon
                v-if="data.item.feed && data.item.feed.length != 0"
                icon="MessageCircleIcon"
                class="d-inline mr-1"
              />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              :disabled="!canEditStyle"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'apps-styles-edit-embellishment',
                       params: { id: currentId, embellishment: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="onEmbellishmentSelect(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
                <span class="align-middle ml-50">Add Comment</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </div>

    <div>
      <b-card
        v-if="feed.length > 0"
      >
        <b-card-body>
          <feed
            ref="refFeed"
            :feed="feed"
            :feed-config="feedConfig"
          />
        </b-card-body>
      </b-card>
    </div>

    <b-modal
      v-model="showCommentsForm"
      ok-only
      ok-title="Add"
      :ok-disabled="!comments.trim()"
      :title="selectedEmbellishmentTitle"
      @ok="addComments"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Comment"
          label-for="comment"
        >
          <b-form-textarea
            id="comment"
            v-model="comments"
            trim
            rows="3"
            max-rows="5"
            required
            autofocus
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCardBody, BDropdown, BDropdownItem, BForm,
  BFormSelect, BFormGroup, BFormTextarea, BInputGroup, BInputGroupAppend,
  BInputGroupText, BModal, BTable,
} from 'bootstrap-vue'
import {
  computed, onUnmounted, ref,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useStyleEmbellishmentsList from './useStyleEmbellishmentsList'
import styleStoreModule from '../styleStoreModule'
import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BModal,
    BTable,

    Feed,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['scroll-to-bottom'],
  expose: ['feed'],
  data() {
    return {}
  },
  setup(props, { emit, root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'
    const toast = useToast()

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const categoryType = ref('Embroidery')
    const categoryTypeOptions = [
      'Embroidery',
      'Print',
      'Heat Transfer',
      'Sublimation',
      'All over Print',
      'Rhinestones',
      'Finishings',
    ]
    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }

    const showCommentsForm = ref(false)
    const selectedEmbellishmentId = ref('')
    const selectedEmbellishmentTitle = ref('')
    const comments = ref('')

    const categoryTypeValue = computed(() => categoryType.value
      .toLowerCase().replaceAll(' ', '-'))

    const currentId = computed(() => router.currentRoute.params.id)

    const {
      fetchStyleEmbellishments,
      tableColumns,
      refEmbellishmentListTable,
      sortBy,
      isSortDirDesc,
      refetchData,
    } = useStyleEmbellishmentsList()

    const feed = () => {
      const allFeeds = []
      if (refEmbellishmentListTable.value) {
        refEmbellishmentListTable.value.localItems.forEach(embellishment => {
          embellishment.feed.forEach(embellishmentFeed => {
            allFeeds.push(embellishmentFeed)
          })
        })
      }
      return allFeeds.sort((a, b) => a.id - b.id)
    }

    const imageMedia = allMedia => allMedia.filter(media => media.image)

    const addComments = () => {
      const feedData = new FormData()
      feedData.append('style_embellishment', selectedEmbellishmentId.value)
      feedData.append('type', 'Comment')
      feedData.append('feed_vars', JSON.stringify({
        comments: comments.value,
        custom_title: selectedEmbellishmentTitle.value,
      }))
      store.dispatch('app-style/addStyleEmbellishmentFeed', feedData)
        .then(() => {
          refetchData()
          comments.value = ''
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comment added',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('scroll-to-bottom')
        })
    }

    const onEmbellishmentSelect = embellishment => {
      selectedEmbellishmentId.value = embellishment.id
      selectedEmbellishmentTitle.value = embellishment.category_type
      if (embellishment.specifics_description) {
        selectedEmbellishmentTitle.value += ` - ${embellishment.specifics_description}`
      }
      showCommentsForm.value = true
    }

    return {
      fetchStyleEmbellishments,
      onEmbellishmentSelect,
      addComments,

      tableColumns,
      refetchData,
      sortBy,
      isSortDirDesc,
      refEmbellishmentListTable,
      categoryType,
      categoryTypeOptions,
      categoryTypeValue,
      currentId,
      feed,
      feedConfig,
      showCommentsForm,
      comments,
      selectedEmbellishmentId,
      selectedEmbellishmentTitle,
      imageMedia,
    }
  },
}
</script>
