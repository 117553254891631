var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column mt-1"},[_c('transition-group',{attrs:{"name":"fade"}},[_vm._l((_vm.feed),function(ref){
var id = ref.id;
var type = ref.type;
var feed_vars = ref.feed_vars;
var image = ref.image;
var created_at = ref.created_at;
var created_by = ref.created_by;
return [(!_vm.applyFilter || _vm.applyFilter.includes(type))?_c('feed-item',{key:id,attrs:{"type":type,"feed-vars":feed_vars,"image":image,"created-at":created_at,"created-by":created_by,"feed-config":_vm.feedConfig}}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }