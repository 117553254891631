<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{invalid}"
    >
      <!-- Style Info: Input Fields -->
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Ref -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Ref"
              rules="required"
            >
              <b-form-group
                label="ref"
                label-for="ref"
              >
                <b-form-input
                  id="ref"
                  v-model="formData.ref"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :disabled="!canEditStyle"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  :disabled="!canEditStyle"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col
            v-if="canEditStatus"
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="style-status"
            >
              <v-select
                v-model="formData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="workflowStatusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-status"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Category -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Category"
              label-for="style-category"
            >
              <v-select
                v-model="formData.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-category"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Client -->
          <b-col
            v-if="userRole !== 'client-admin' && userRole !== 'client'"
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Client"
              rules="required"
            >
              <b-form-group
                label="Client"
                label-for="style-client"
              >
                <v-select
                  v-model="formData.client"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="!canEditStyle"
                  input-id="style-client"
                  @input="refreshOptions"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Brand -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Brand"
              rules="required"
            >
              <b-form-group
                label="Brand"
                label-for="style-brand"
              >
                <v-select
                  v-model="formData.brand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="brandFilteredOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="style-brand"
                  :disabled="!canEditStyle"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Collection -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Collection"
              label-for="style-collection"
            >
              <v-select
                v-model="formData.collection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="collectionFilteredOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="style-collection"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Client Contact -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Client Contact"
              label-for="style-client-contact"
            >
              <v-select
                v-model="formData.client_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientContactUserFilteredOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-client-contact"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Supplier Contact -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Supplier Contact"
              label-for="style-supplier-contact"
            >
              <v-select
                v-model="formData.supplier_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="supplierContactUserOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-supplier-contact"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || !canEditStyle"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEditStyle"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    collectionOptions: {
      type: Array,
      required: true,
    },
    clientContactUserOptions: {
      type: Array,
      required: true,
    },
    supplierContactUserOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const toast = useToast()

    const formData = ref({ ...props.styleData })
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const brandFilteredOptions = ref([])
    const collectionFilteredOptions = ref([])
    const clientContactUserFilteredOptions = ref([])

    watch(() => props.styleData.status, newValue => {
      if (newValue !== undefined) {
        formData.value.status = newValue
      }
    }, { deep: true, immediate: false })

    const onSubmit = () => {
      const styleData = {
        id: router.currentRoute.params.id,
        ...formData.value,
      }
      store.dispatch('app-style/editStyle', styleData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Style edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'apps-styles-list' })
        })
    }

    const initOptions = () => {
      if (userRole === 'client' || userRole === 'client-admin') {
        brandFilteredOptions.value = [
          ...props.brandOptions,
        ]
        collectionFilteredOptions.value = [
          ...props.collectionOptions,
        ]
        clientContactUserFilteredOptions.value = [
          ...props.clientContactUserOptions,
        ]
      } else {
        brandFilteredOptions.value = props.brandOptions
          .filter(option => (option.client === props.styleData.client))
        collectionFilteredOptions.value = props.collectionOptions
          .filter(option => (option.client === props.styleData.client))
        clientContactUserFilteredOptions.value = props.clientContactUserOptions
          .filter(option => (option.client === props.styleData.client))
      }
    }
    initOptions()

    const resetFormData = () => {
      formData.value = { ...props.styleData }
      initOptions()
    }

    const refreshOptions = client => {
      brandFilteredOptions.value = props.brandOptions
        .filter(option => (option.client === client))
      collectionFilteredOptions.value = props.collectionOptions
        .filter(option => (option.client === client))
      clientContactUserFilteredOptions.value = props.clientContactUserOptions
        .filter(option => (option.client === client))

      formData.value = {
        ...formData.value,
        brand: '',
        collection: '',
        client_contact_user: '',
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      onSubmit,
      formData,
      userRole,

      brandFilteredOptions,
      collectionFilteredOptions,
      clientContactUserFilteredOptions,
      refreshOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
