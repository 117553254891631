<template>
  <div>
    <div
      v-if="measurements.length > 0"
    >
      <b-button
        variant="primary"
        @click="() => showMeasurements = !showMeasurements"
      >
        {{ showMeasurements ? 'Hide Measurements': 'Show Measurements' }}
      </b-button>
      <b-table-simple
        v-show="showMeasurements"
        style="width:50%"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="p-1"
            >REF</b-th>
            <b-th
              class="p-1"
            >Measurement</b-th>
            <b-th
              class="p-1"
            >Tol (+/-)</b-th>
            <b-th
              class="p-1"
            >{{ sampleSize }}</b-th>
            <b-th
              class="p-1"
            >Fit</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, index) in measurements"
            :key="index"
          >
            <b-td
              class="p-1"
            >{{ row.ref }}</b-td>
            <b-td
              class="p-1"
            >{{ row.description }}</b-td>
            <b-td
              class="p-1"
            >{{ row.tol }}</b-td>
            <b-td
              class="p-1"
            >{{ row[sampleSize] }}</b-td>
            <b-td
              class="p-1"
              :style="validFit(row)"
            >{{ row.measure }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <b-img
      v-if="image"
      rounded
      height="120"
      :src="image"
    />
    <div
      v-if="comments"
    >
      <span
        v-for="(line, index) in comments.split('\n')"
        :key="index"
      >
        {{ line }}<br>
      </span>
    </div>
  </div>
</template>

<script>
import {
  BButton, BImg, BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BImg,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,
  },
  props: {
    comments: {
      type: String,
      required: false,
      default: '',
    },
    measurements: {
      type: Array,
      required: false,
      default: Array,
    },
    sampleSize: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const showMeasurements = ref(false)

    const validFit = record => {
      if (!props.sampleSize) {
        return {}
      }
      const measure = +record.measure
      if (!measure) return {}

      const tol = +record.tol
      const size = +record[props.sampleSize]
      if (measure < size - tol || measure > size + tol) {
        return { backgroundColor: 'red', color: 'white' }
      }
      return {}
    }

    return {
      showMeasurements,
      validFit,
    }
  },
}
</script>
